:root {
    /* Global vars for corporate group revenue details table */
    --first-col-width: 220px;
    --data-col-width: 100px;
}

.vs-green {
    color: #78bb43;
}

.bg-vs-green {
    background: #78bb43 !important;
}

.rx-purple {
    color: #802fea;
}

/* dvmReach overrides */
.reach-select {
    input {
        cursor: pointer;

        &:focus {
            border: 1px solid var(--mantine-color-dvm-blue-filled);
        }
    }
}

.reach-multiselect {
    .mantine-MultiSelect-input {
        &:focus-within {
            outline: none;
            border: 1px solid var(--mantine-color-dvm-blue-filled);
        }
    }
}

.reach-link {
    color: var(--mantine-color-dvm-blue-filled) !important;

    &:visited {
        color: var(--mantine-color-dvm-blue-filled) !important;
    }
}

.reach-input {
    input {
        &:focus {
            border: 1px solid var(--mantine-color-dvm-blue-filled);
        }
    }

    textarea {
        &:focus {
            border: 1px solid var(--mantine-color-dvm-blue-filled);
        }
    }
}

.reach-datepicker {
    .mantine-DatePicker-day {
        /* Order is important with these */
        &[data-selected] {
            background-color: var(--mantine-color-dvm-blue-filled);
            color: white;
        }

        &[data-in-range] {
            background-color: color-mix(
                in srgb,
                var(--mantine-color-dvm-blue-filled) 20%,
                transparent
            );
            color: black;
        }

        &[data-weekend] {
            color: red;
        }

        &[data-first-in-range] {
            background-color: var(--mantine-color-dvm-blue-filled);
            color: white;
            opacity: 1;
        }

        &[data-last-in-range] {
            background-color: var(--mantine-color-dvm-blue-filled);
            color: white;
            opacity: 1;
        }
    }
}

/* dvmRx overrides */
.rx-select {
    input {
        cursor: pointer;
    }
}

.rx-link {
    color: var(--mantine-color-rx-purple-filled);

    &:visited {
        color: var(--mantine-color-rx-purple-filled);
    }
}

/* Override for all modal titles in the app */
.mantine-Modal-title {
    font-size: 1.375rem !important;
    font-weight: 500 !important;
}

/* Override for any TagsInput elements in the app */
.mantine-TagsInput-input {
    &:focus-within {
        border: 1px solid var(--mantine-color-dvm-blue-filled);
    }
}

/* We need some breakpoints for the logo and icon in the top bar */
.top-bar-logo {
    display: none !important;

    /* Only display the logo (dvmReach, dvmRx) when screen is >= 591px */
    @media (min-width: 591px) {
        display: block !important;
    }
}

.top-bar-icon {
    display: none !important;

    /* Only display the icon between 434px and 590px */
    @media (min-width: 434px) and (max-width: 590px) {
        display: block !important;
    }

    /* Hide the icon (no text) when screen is >= 591px */
    @media (min-width: 591px) {
        display: none !important;
    }
}

.dashboard-container {
    /* 60px for navbar header */
    /* 45px for page title */
    /* 12px for top padding */
    /* 32px for bottom padding */
    height: calc(100vh - 60px - 45px - 12px - 32px);
}

.card-hover-shadow {
    transition: box-shadow 0.25s ease;

    &:hover {
        --paper-shadow: var(--mantine-shadow-sm);
    }
}

.mantine-Spotlight-action[data-selected] {
    background-color: var(--mantine-color-dark-6);
}
